
// @ts-ignore
import { riseid ,companyRiseid ,teamRiseid ,userRiseid ,nanoid ,userNanoid ,allNanoids } from '../../../brands.js'
// @ts-ignore

// @ts-ignore

// @ts-ignore

import { z } from 'zod'

export const rise_entities = z.object({
	riseid: companyRiseid.or(userRiseid).or(teamRiseid),
	type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','user','company','team']),
	parent_riseid: companyRiseid.or(userRiseid).or(teamRiseid),
	nanoid: allNanoids,
	avatar: z.string().trim().optional().default(''),
	last_modified_by: userNanoid.nullish(),
	created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
})

export const insertable_rise_entities = z.object({
  riseid: companyRiseid.or(userRiseid).or(teamRiseid),
  type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','user','company','team']),
  parent_riseid: companyRiseid.or(userRiseid).or(teamRiseid),
  nanoid: allNanoids,
  avatar: z.string().trim().optional().default(''),
  last_modified_by: userNanoid.nullish(),
})

export const updateable_rise_entities = z.object({
  riseid: companyRiseid.or(userRiseid).or(teamRiseid).optional(),
  type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','user','company','team']).optional(),
  parent_riseid: companyRiseid.or(userRiseid).or(teamRiseid).optional(),
  nanoid: allNanoids.optional(),
  avatar: z.string().trim().optional().default(''),
  last_modified_by: userNanoid.nullish(),
})

export const selectable_rise_entities = z.object({
  riseid: companyRiseid.or(userRiseid).or(teamRiseid),
  type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','user','company','team']),
  parent_riseid: companyRiseid.or(userRiseid).or(teamRiseid),
  nanoid: allNanoids,
  avatar: z.string(),
  last_modified_by: userNanoid.nullish(),
  created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
})

export type RiseEntitiesType = z.infer<typeof rise_entities>
export type InsertableRiseEntitiesType = z.infer<typeof insertable_rise_entities>
export type UpdateableRiseEntitiesType = z.infer<typeof updateable_rise_entities>
export type SelectableRiseEntitiesType = z.infer<typeof selectable_rise_entities>

import type { ActionResponse, RSK } from "./types";

export const iframe = document.createElement("iframe");
iframe.style.display = "none";
iframe.setAttribute(
  "allow",
  "publickey-credentials-get *;publickey-credentials-create *"
);
document.body.appendChild(iframe);
const rskWindow = iframe.contentWindow as Window;

function responsePromise(type: string) {
  return new Promise((resolve, reject) => {
    const listener = (e: ActionResponse) => {
      if (e.data.type === `${type}-response`) {
        window.removeEventListener("message", listener);
        if (e.data.error) {
          reject(e.data.error);
        } else {
          resolve(e.data.data);
        }
      }
    };
    window.addEventListener("message", listener);
  });
}

export default (): RSK => {
  const api = ["signTypedData", "getAddress"];
  const sdk: any = {
    create: async (email: string) => {
      return new Promise((resolve, reject) => {
        const type = "rsk-create";
        rskWindow.postMessage({ type, data: { email } }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    },
    connect: async () => {
      return new Promise((resolve, reject) => {
        const type = "rsk-connect";
        rskWindow.postMessage({ type }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    },
    signMessage: async (message: string) => {
      return new Promise((resolve, reject) => {
        const type = "rsk-signMessage";
        rskWindow.postMessage({ type, data: { message } }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    },
    setup: (url: string) => {
      iframe.src = url;
    },
  };
  api.forEach((method) => {
    sdk[method] = (data: any) => {
      return new Promise((resolve, reject) => {
        const type = `rsk-${method}`;
        rskWindow.postMessage({ type, data }, "*");
        responsePromise(type).then(resolve).catch(reject);
      });
    };
  });
  return sdk;
};

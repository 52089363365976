import { defineStore } from "pinia";
import { z } from "zod";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/action_items";

const getActionItemsResponseSchema =
  schema["/dashboard/action_items"].get.response[200].shape.data;

export const useActionItemsStore = defineStore("action_items", {
  state: (): {
    actionItems: z.infer<typeof getActionItemsResponseSchema>;
  } => ({
    actionItems: [],
  }),
  actions: {
    async getActionItems() {
      const response = await this.$client["/dashboard/action_items"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      this.actionItems = getActionItemsResponseSchema.parse(responseJson.data);
    },
  },
});

import { z } from 'zod'
export const blockchainBigInt = z.string().regex(/^\d+$/)
export const blockchainAddress = z.string().regex(/^0x[a-fA-F0-9]{40}$/)
export const blockchainTx = z.string().regex(/^0x[a-fA-F0-9]{64}$/)
export const blockchainHash = blockchainTx
export const phone = z.string().regex(/^\+?[0-9 -]+$/)
export const blockchainSignature = z.string().regex(/^0x[a-fA-F0-9]{130}$/)
export const website = z.string().regex(/^(|http(s?):\/\/[^\s]+)$/)
export const email = z.string().email()
export const countryISO2 = z.string().regex(/^[A-Z]{2}$/)
export const companyDocumentTypes = z.enum(['SOW', 'PSA'])
export type CompanyDocumentTypes = z.infer<typeof companyDocumentTypes>
export const dateLike = z
	.union([z.number(), z.string(), z.date()])
	.pipe(z.coerce.date())
export const boolLike = z
	.union([z.number(), z.string(), z.boolean()])
	.pipe(z.coerce.boolean())
export const fetsFormData = z.object({
	file: z.any(),
	filename: z.string().min(1),
	mimetype: z.enum(['application/pdf', 'image/jpeg', 'image/png']),
})
export const recaptcha = z.object({
	'x-recaptcha-token': z.string().min(1),
	'x-recaptcha-action': z.string().min(1),
})
export const v1Auth = z.object({
	'x-auth-token': z.string().min(1),
})
export const pagination = z.object({
	total_records: z.coerce.number(),
	current_page: z.coerce.number(),
	total_pages: z.coerce.number(),
	next_page: z.coerce.number().nullable(),
	prev_page: z.coerce.number().nullable(),
})
export const encryptedWallet = z.object({
	address: z.string(),
	id: z.string(),
	version: z.number(),
	Crypto: z.object({
		cipher: z.string(),
		cipherparams: z.object({
			iv: z.string(),
		}),
		ciphertext: z.string(),
		kdf: z.string(),
		kdfparams: z.object({
			salt: z.string(),
			n: z.number(),
			dklen: z.number(),
			p: z.number(),
			r: z.number(),
		}),
		mac: z.string(),
	}),
	'x-ethers': z.object({
		client: z.string(),
		gethFilename: z.string(),
		path: z.string(),
		locale: z.string(),
		mnemonicCounter: z.string(),
		mnemonicCiphertext: z.string(),
		version: z.string(),
	}),
})
const paginationCursorFormat = z.object({
	idx: z.number(),
})
export const paginationCursor = z.string().transform((c) => {
	return paginationCursorFormat.parse(
		JSON.parse(Buffer.from(c, 'base64').toString()),
	)
})

import { type ClientPlugin, type NormalizeOAS, createClient } from "fets";
import { toast } from "vue-sonner";

import type openapi from "~/.nuxt/contracts/src/codegen/openapi/rise";

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  const config = useRuntimeConfig();

  function authPlugin(): ClientPlugin {
    return {
      async onRequestInit({ requestInit, path }) {
        function hasNonLetters(inputString: string) {
          const nonLetterRegex = /[^a-zA-Z]/;
          return nonLetterRegex.test(inputString);
        }
        const actionName = path
          .split("/")
          .filter((e) => !hasNonLetters(e))
          .join("");

        if (!app.config.globalProperties.$clerk?.session) {
          // @ts-expect-error I couldn't add $router type to nuxtApp
          // nuxtApp.$router.push("/sign-out");
          return;
        }

        const token = await app.config.globalProperties.$recaptcha(actionName);
        const jwt = await app.config.globalProperties.$clerk.session.getToken({
          template: "User_Template",
        });

        requestInit.headers = {
          ...requestInit.headers,
          Authorization: `Bearer ${jwt}`,
          "x-recaptcha-token": token,
          "x-recaptcha-action": actionName,
          "x-request-id": "abububle",
        };
      },
      onFetch({ fetchFn, setFetchFn }) {
        setFetchFn(async (input, init) => {
          const response = await fetchFn(input, init);

          if (!response.ok && response.status === 401) {
            // @ts-expect-error I couldn't add $router type to nuxtApp
            nuxtApp.$router.push("/sign-out");
            return response;
          } else if (!response.ok) {
            const responseJson = await response.json();
            toast.error(responseJson?.data || "Api error");
            console.log(
              `Request failed: ${
                init && init.method ? init.method.toUpperCase() : ""
              } ${response.url}\nRequest ID: %c${response.headers.get(
                "x-request-id"
              )}`,
              "background-color: red; color: white"
            );
          }
          return response;
        });
      },
    };
  }

  function toastPlugin(): ClientPlugin {
    return {
      onFetch({ fetchFn, setFetchFn }) {
        setFetchFn(async (input, init) => {
          const response = await fetchFn(input, init);
          if (!response.ok) {
            const responseJson = await response.json();
            toast.error(responseJson?.message || "Api error");
          }
          return response;
        });
      },
    };
  }

  const client = createClient<NormalizeOAS<typeof openapi>>({
    endpoint: config.public.apiUrl as
      | "https://dev-pay-api.riseworks.dev"
      | "https://staging-pay-api.riseworks.io"
      | "https://pay-api.riseworks.io",
    plugins: [authPlugin()],
  });

  const publicClient = createClient<NormalizeOAS<typeof openapi>>({
    endpoint: config.public.apiUrl as
      | "https://dev-pay-api.riseworks.dev"
      | "https://staging-pay-api.riseworks.io"
      | "https://pay-api.riseworks.io",
    plugins: [toastPlugin()],
  });

  app.config.globalProperties.$client = client;
  app.provide("client", client);

  app.config.globalProperties.$publicClient = publicClient;
  app.provide("publicClient", publicClient);
});

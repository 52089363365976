import BigNumber from "bignumber.js";

function isValidDate(value: string) {
  const d = new Date(value);

  return d instanceof Date && !isNaN(d as unknown as number);
}

const filters = {
  currency(value: number) {
    const amount = value || 0;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  },
  walletAddress(address: string) {
    if (!address || address?.length <= 0 || typeof address !== "string") return;

    const firstSixChars = address.slice(0, 6);
    const lastFourChars = address.slice(-4);

    return `${firstSixChars}...${lastFourChars}`;
  },
  walletNetwork(network: string) {
    const runtimeConfig = useRuntimeConfig();
    if (!network || network?.length <= 0 || typeof network !== "string") return;

    if (network === "arbitrum") {
      return runtimeConfig.public.NODE_ENV === "production" ? "arb1:" : "arb4:";
    }

    return runtimeConfig.public.NODE_ENV === "production" ? "eth:" : "goer:";
  },
  bigNumberDecimalPlaces(amount: BigNumber, decimalPlaces = 8) {
    const number = new BigNumber(amount);
    return new BigNumber(number.toFixed(decimalPlaces)).valueOf();
  },
  // tokenToDecimal(token) {
  //   return Number.parseFloat(ethers.utils.formatUnits(token, 6)).toFixed(2);
  // },
  ABARoutingNumberIsValid(routingNumberToTest: string) {
    if (!routingNumberToTest) {
      // all 0's is technically a valid routing number, but it's inactive
      return false;
    }

    const routing = routingNumberToTest.toString();

    // gotta be 9  digits
    const match = routing.match("^\\d{9}$");
    if (!match) {
      return false;
    }

    // The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
    // https://en.wikipedia.org/wiki/Routing_transit_number
    const firstTwo = parseInt(routing.substring(0, 2));
    const firstTwoValid =
      (firstTwo >= 0 && firstTwo <= 12) ||
      (firstTwo >= 21 && firstTwo <= 32) ||
      (firstTwo >= 61 && firstTwo <= 72) ||
      firstTwo === 80;
    if (!firstTwoValid) return false;

    // this is the checksum
    // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
    const weights = [3, 7, 1];
    let sum = 0;
    for (let i = 0; i < 8; i++) sum += parseInt(routing[i]) * weights[i % 3];

    return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
  },
  providerLogo(provider: string) {
    if (!provider) return;

    const providers = {
      brave: "/icons/brave.svg",
      metamask: "/icons/metamask.svg",
      "coinbase wallet": "/icons/coinbase.svg",
      coinbase: "/icons/coinbase.svg",
      mew: "/icons/mew.png",
      "mew wallet": "/icons/mew.png",
      torus: "/icons/torus.svg",
      rise: "/brand/rise-symbol.svg",
      "security key": "/brand/rise-symbol.svg",
      other: "/icons/other.svg",
    };

    return providers[provider as keyof typeof providers];
  },
  time(value: string) {
    if (!isValidDate(value)) return "";

    const date = new Date(value);

    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  },
  dateTable(value: string) {
    if (!isValidDate(value)) return "";

    const date = new Date(value);

    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }).format(date);
  },
  shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

    return `#${RR}${GG}${BB}`;
  },
  recoveryEmail(email: string) {
    if (!email || email?.length <= 0 || typeof email !== "string") return;
    const emailParts = email.split("@");
    const firstChar = emailParts[0].slice(0, 1);
    const lastChar = emailParts[0].slice(-1);

    return `${firstChar}...${lastChar}@${emailParts[1]}`;
  },
  deviceSystem(userAgent: string) {
    if (!userAgent) return;

    const userSystems = {
      macintosh: "Mac OS",
      windows: "Windows",
      ubuntu: "Linux",
      iphone: "iOS",
      android: "Android",
    };

    const currentDevice = Object.keys(userSystems).find((e) =>
      userAgent.toLowerCase().includes(e)
    );

    return userSystems[currentDevice as keyof typeof userSystems];
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  app.config.globalProperties.$filters = filters;
  app.provide("filters", filters);
});

export type Filters = Record<keyof typeof filters, (value: string) => void>;

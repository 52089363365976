
// @ts-ignore
import { email ,nanoid } from '../../../brands.js'
// @ts-ignore

// @ts-ignore

// @ts-ignore

import { z } from 'zod'

export const invites = z.object({
	email: z.string().trim().min(1),
	role: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee']),
	status: z.enum(['pending','accepted','canceled','rejected']),
	nanoid: z.string().trim().min(1),
	anonymous: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
	prefill: z.string().trim().min(1),
	invited_by: z.string().trim().min(1),
	invited_to: z.string().trim().min(1),
	expires_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
	created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	last_modified_by: z.string().trim().nullish(),
})

export const insertable_invites = z.object({
  email: z.string().trim().min(1),
  role: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee']),
  status: z.enum(['pending','accepted','canceled','rejected']),
  nanoid: z.string().trim().min(1),
  anonymous: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
  prefill: z.string().trim().min(1),
  invited_by: z.string().trim().min(1),
  invited_to: z.string().trim().min(1),
  expires_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  last_modified_by: z.string().trim().nullish(),
})

export const updateable_invites = z.object({
  email: z.string().trim().min(1).optional(),
  role: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee']).optional(),
  status: z.enum(['pending','accepted','canceled','rejected']).optional(),
  nanoid: z.string().trim().min(1).optional(),
  anonymous: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
  prefill: z.string().trim().min(1).optional(),
  invited_by: z.string().trim().min(1).optional(),
  invited_to: z.string().trim().min(1).optional(),
  expires_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
  last_modified_by: z.string().trim().nullish(),
})

export const selectable_invites = z.object({
  email: z.string(),
  role: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee']),
  status: z.enum(['pending','accepted','canceled','rejected']),
  nanoid: z.string(),
  anonymous: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()),
  prefill: z.string(),
  invited_by: z.string(),
  invited_to: z.string(),
  expires_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  last_modified_by: z.string().nullable(),
})

export type InvitesType = z.infer<typeof invites>
export type InsertableInvitesType = z.infer<typeof insertable_invites>
export type UpdateableInvitesType = z.infer<typeof updateable_invites>
export type SelectableInvitesType = z.infer<typeof selectable_invites>

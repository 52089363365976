import { defineStore } from "pinia";
import { getAllCountries, getAllTimezones } from "countries-and-timezones";

export const useCountriesStore = defineStore("countries", {
  state: (): {
    countriesItems: { label: string; value: string }[];
    timezonesItems: { label: string; value: string }[];
    countriesMap: Record<string, string>;
  } => ({
    countriesItems: [],
    timezonesItems: [],
    countriesMap: {},
  }),

  actions: {
    getTimezones() {
      const timezones = getAllTimezones();
      const timezonesKeys = Object.keys(timezones);

      this.timezonesItems = timezonesKeys.map((timezone: string) => {
        return {
          label: `${timezones[timezone as keyof typeof timezones].name.replace(
            "_",
            " "
          )} ${timezones[timezone as keyof typeof timezones].utcOffsetStr}`,
          value: `${timezones[timezone as keyof typeof timezones].name.replace(
            "_",
            " "
          )} ${timezones[timezone as keyof typeof timezones].utcOffsetStr}`,
        };
      });
    },
    getCountries() {
      const countries = getAllCountries();
      const countriesKeys = Object.keys(countries);

      this.countriesMap = countriesKeys.reduce(
        (acc: Record<string, string>, country: string) => {
          acc[countries[country as keyof typeof countries].id] =
            countries[country as keyof typeof countries].name;
          return acc;
        },
        {}
      );

      this.countriesItems = countriesKeys.map((country: string) => {
        return {
          label: countries[country as keyof typeof countries].name,
          value: countries[country as keyof typeof countries].id,
        };
      });
    },
  },
});

import { default as _91documentType_93geTfuJkmSxMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue?macro=true";
import { default as indexPaehKq8T7PMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue?macro=true";
import { default as activityovNl6XaQjNMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue?macro=true";
import { default as addresswTAGcfiEKxMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue?macro=true";
import { default as developerreHJgcsbaBMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as _91organizationId_933a7ZUEx3DcMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as indexZ8nC7ewJDQMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as perksUAgndZ6TRWMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as profileQI1YEEAGEjMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue?macro=true";
import { default as referralTUs8exLaNQMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as riseid4c5I65Joe1Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue?macro=true";
import { default as securitydSUy0T0XEJMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as settingsaYfaoJO9kCMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue?macro=true";
import { default as _91uuid_936oI1Ps3OSKMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93WVCOZkAlaIMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45wallet6Rdypfmi2OMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binanceDQaUmA7eRSMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbaseyNJnNudh4dMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdqFk3bXSGtGMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletua3Ubnh4zSMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europeOst7p8d9l9Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexDNgchc4MXjMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbp5OK10CpE1tMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultIjsxvS0UHMMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdrPmOCgVgaHMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenVHYDOeAw0GMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngnGZ1sYIyNWaMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as adddZrNRTIAvTMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexxX79bZRSzzMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardDJbP9rpZMkMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as completemuQqNUh3ESMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingSPPexMuxF9Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_93Wfvj6jLh5TMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexgReRg2YhNfMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexW9ORI2k6qFMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue?macro=true";
import { default as tasksmzi7078uY9Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as indexeLUQsEVBhIMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue?macro=true";
import { default as _91nanoid_93Cnm2rzndCSMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue?macro=true";
import { default as indexLEApm8e9PvMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue?macro=true";
import { default as cryptoUL7UBrJBuGMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiat8iHPnxVTvDMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directiv9O5mhoLxMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdrawa0o7cQiT4HMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as arbitrum_45walletYicyrIejlyMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binancezAtj4Dy1kSMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbaset2RH7bh9oVMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdunK7GHaClSMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45wallet0IX0WaoB0fMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europeHks6x1JrCpMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexdmsSKBm6rPMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbpJNE5kY48JLMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultz22U1xuEXOMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdSM30aNs3CwMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenAWVAeh6ZkMMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngnIqKmdxhuCfMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addW0aCgSM9GeMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add.vue?macro=true";
import { default as index8cCdz2npcKMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/index.vue?macro=true";
import { default as benefitsJug3Oj2C69Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboardMadglW9C7mMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as completeVMENvqH2ZoMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingWNpmxaW5OiMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue?macro=true";
import { default as pay_45slipsTCWtmfyojOMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as _91id_93YOFqkHTjAiMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as index5CEgKPajHJMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexOu7ogs2qPAMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue?macro=true";
import { default as reimbursementsNrJE8jQXzdMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksCkYfmVlfTPMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as cryptoaqxpU15c1zMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiatKPpWqqZvWrMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as withdrawTxYcfam4UMMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as indexAISrcwSZnpMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_93DhVecXFPaxMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtRf46OdOePUMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as callbackyR6ZOTyen2Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue?macro=true";
import { default as administratorsXHSAXvnaFXMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboardxtXuXcLhmVMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as complete9mT1D50l3AMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingXsRa4GspBHMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directp15IJcfYSBMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmIgnOnJ3dpYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesAufbLPgR4uMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as addressmbKUcBopR6Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue?macro=true";
import { default as configs8bbGVyYWBMMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue?macro=true";
import { default as detailsrFwRfzHKqmMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue?macro=true";
import { default as ownershipRYX0M8xUXSMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue?macro=true";
import { default as settingsDtH7JxzCW6Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue?macro=true";
import { default as subscriptionQMsdHB2cphMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as tasksFnOb9XUCpoMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addysk932FtQdMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as index6Fpbj22pNaMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transferx9V4UQXCfiMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45addressHnu9ufRSJeMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wireCA0LuVNxnYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositY7CXdIGLb3Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundbIjWYnAXTWMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93v3I2rym6EJMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as external2uL3Ag0xRPMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2farAEZUMQ1AsMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as code09XVr1d2XXMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indexVbSegH3vBZMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45outoUPR0elBV7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codew1rkiQsTw7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexqS5moKTc3lMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingd8RmpVFioDMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as dashboardGfzbiY0VyuMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as dashboardOLDzU8Ejd3EK7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue?macro=true";
import { default as blockchain_45addressenDIdlYaDmMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wire3m5ic3hPBQMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as deposit1E4VR8xxwlMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as editmEZDEdWcsPMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue?macro=true";
import { default as completeRqgDP333FOMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pending93Rbr94hypMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as batchAwTHH1nfBaMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directDFU59zLlTjMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmwD5lqSb1CGMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invites8BkmnC0MOMMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as benefitsJoVJ7ILv5wMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summaryBAnwPmWrU6Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_93v4xm7XUI6KMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexrWNnKlB6f4Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csvEJaDuLy8muMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as indexYJpqpUihxZMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexENy9fBQJcmMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directgzCfLGjvv7Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as instantP1pbOIZMrCMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestoneNvU4JTH9xNMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as oncemt11Qcr7mfMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringN12hEh2pLOMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_938QH57J92H6Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payableS4VyrPpeRfMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue?macro=true";
import { default as indexfaHOPEzZYYMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/index.vue?macro=true";
import { default as settingsiXMrkXwtjLMeta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasksuumH2raBU4Meta } from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "scope-workspaceId-documents-documentId-versionId-documentType",
    path: "/:scope()/:workspaceId()/documents/:documentId()/:versionId()/:documentType()",
    meta: _91documentType_93geTfuJkmSxMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-documents",
    path: "/:scope()/:workspaceId()/documents",
    meta: indexPaehKq8T7PMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "account-activity",
    path: "/account/activity",
    meta: activityovNl6XaQjNMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue").then(m => m.default || m)
  },
  {
    name: "account-address",
    path: "/account/address",
    meta: addresswTAGcfiEKxMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerreHJgcsbaBMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_933a7ZUEx3DcMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: indexZ8nC7ewJDQMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksUAgndZ6TRWMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileQI1YEEAGEjMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralTUs8exLaNQMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-riseid",
    path: "/account/riseid",
    meta: riseid4c5I65Joe1Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securitydSUy0T0XEJMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsaYfaoJO9kCMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: adddZrNRTIAvTMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexxX79bZRSzzMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardDJbP9rpZMkMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: completemuQqNUh3ESMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingSPPexMuxF9Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat-id",
    path: "/contractors/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93Wfvj6jLh5TMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat",
    path: "/contractors/:workspaceId()/receivable/flat",
    meta: indexgReRg2YhNfMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable",
    path: "/contractors/:workspaceId()/receivable",
    meta: indexW9ORI2k6qFMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksmzi7078uY9Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries",
    path: "/contractors/:workspaceId()/time_entries",
    meta: indexeLUQsEVBhIMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice-nanoid",
    path: "/contractors/:workspaceId()/time_entries/invoice/:nanoid()",
    meta: _91nanoid_93Cnm2rzndCSMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice",
    path: "/contractors/:workspaceId()/time_entries/invoice",
    meta: indexLEApm8e9PvMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdrawa0o7cQiT4HMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-accounts-add",
    path: "/employees/:workspaceId()/accounts/add",
    meta: addW0aCgSM9GeMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "employees-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-accounts",
    path: "/employees/:workspaceId()/accounts",
    meta: index8cCdz2npcKMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsJug3Oj2C69Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboardMadglW9C7mMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-complete",
    path: "/employees/:workspaceId()/history/complete",
    meta: completeVMENvqH2ZoMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-pending",
    path: "/employees/:workspaceId()/history/pending",
    meta: pendingWNpmxaW5OiMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slipsTCWtmfyojOMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat-id",
    path: "/employees/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93YOFqkHTjAiMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat",
    path: "/employees/:workspaceId()/receivable/flat",
    meta: index5CEgKPajHJMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable",
    path: "/employees/:workspaceId()/receivable",
    meta: indexOu7ogs2qPAMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsNrJE8jQXzdMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksCkYfmVlfTPMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdrawTxYcfam4UMMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "employees-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexAISrcwSZnpMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_93DhVecXFPaxMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackyR6ZOTyen2Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administratorsXHSAXvnaFXMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboardxtXuXcLhmVMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: complete9mT1D50l3AMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pendingXsRa4GspBHMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: invitesAufbLPgR4uMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-settings",
    path: "/organizations/:workspaceId()/settings",
    meta: settingsDtH7JxzCW6Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-settings-address",
    path: "address",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-configs",
    path: "configs",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-details",
    path: "details",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-ownership",
    path: "ownership",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscriptionQMsdHB2cphMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: tasksFnOb9XUCpoMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addysk932FtQdMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: index6Fpbj22pNaMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transferx9V4UQXCfiMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositY7CXdIGLb3Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressHnu9ufRSJeMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wireCA0LuVNxnYMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93v3I2rym6EJMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue").then(m => m.default || m)
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: external2uL3Ag0xRPMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: code09XVr1d2XXMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexVbSegH3vBZMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codew1rkiQsTw7Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexqS5moKTc3lMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingd8RmpVFioDMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboardGfzbiY0VyuMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboardOLD",
    path: "/teams/:workspaceId()/dashboardOLD",
    meta: dashboardOLDzU8Ejd3EK7Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: deposit1E4VR8xxwlMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressenDIdlYaDmMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wire3m5ic3hPBQMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-edit",
    path: "/teams/:workspaceId()/edit",
    meta: editmEZDEdWcsPMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: completeRqgDP333FOMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pending93Rbr94hypMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invites8BkmnC0MOMMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_93v4xm7XUI6KMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexrWNnKlB6f4Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csvEJaDuLy8muMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: indexYJpqpUihxZMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexENy9fBQJcmMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_938QH57J92H6Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directgzCfLGjvv7Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instantP1pbOIZMrCMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestoneNvU4JTH9xNMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: oncemt11Qcr7mfMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringN12hEh2pLOMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payable",
    path: "/teams/:workspaceId()/payable",
    meta: payableS4VyrPpeRfMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: indexfaHOPEzZYYMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsiXMrkXwtjLMeta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasksuumH2raBU4Meta || {},
    component: () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue").then(m => m.default || m)
  }
]

// @ts-ignore
import { riseid ,nanoid } from '../../../brands.js'
// @ts-ignore

// @ts-ignore

// @ts-ignore

import { z } from 'zod'

export const users_onboarding = z.object({
	nanoid: z.string().trim().min(1),
	step: z.enum(['initial','details','compliance_process','security','signing','complete','riseid','signer','v1_migration_in_progress','v1_migration_need_rsk']).optional().default('initial'),
	role: z.enum(['payer','payee','viewer','admin']).optional().default('viewer'),
	moderation_status: z.enum(['pending','submitted','approved','rejected']).optional().default('pending'),
	moderation_internal_note: z.string().trim().optional().default(''),
	moderation_public_note: z.string().trim().optional().default(''),
	accepted_invites: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
	register_business: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
	created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	last_modified_by: z.string().trim().nullish(),
})

export const insertable_users_onboarding = z.object({
  nanoid: z.string().trim().min(1),
  step: z.enum(['initial','details','compliance_process','security','signing','complete','riseid','signer','v1_migration_in_progress','v1_migration_need_rsk']).optional().default('initial'),
  role: z.enum(['payer','payee','viewer','admin']).optional().default('viewer'),
  moderation_status: z.enum(['pending','submitted','approved','rejected']).optional().default('pending'),
  moderation_internal_note: z.string().trim().optional().default(''),
  moderation_public_note: z.string().trim().optional().default(''),
  accepted_invites: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
  register_business: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
  last_modified_by: z.string().trim().nullish(),
})

export const updateable_users_onboarding = z.object({
  nanoid: z.string().trim().min(1).optional(),
  step: z.enum(['initial','details','compliance_process','security','signing','complete','riseid','signer','v1_migration_in_progress','v1_migration_need_rsk']).optional().default('initial'),
  role: z.enum(['payer','payee','viewer','admin']).optional().default('viewer'),
  moderation_status: z.enum(['pending','submitted','approved','rejected']).optional().default('pending'),
  moderation_internal_note: z.string().trim().optional().default(''),
  moderation_public_note: z.string().trim().optional().default(''),
  accepted_invites: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
  register_business: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()).optional().default(false),
  last_modified_by: z.string().trim().nullish(),
})

export const selectable_users_onboarding = z.object({
  nanoid: z.string(),
  step: z.enum(['initial','details','compliance_process','security','signing','complete','riseid','signer','v1_migration_in_progress','v1_migration_need_rsk']),
  role: z.enum(['payer','payee','viewer','admin']),
  moderation_status: z.enum(['pending','submitted','approved','rejected']),
  moderation_internal_note: z.string(),
  moderation_public_note: z.string(),
  accepted_invites: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()),
  register_business: z.union([z.number(),z.string(),z.boolean()]).pipe(z.coerce.boolean()),
  created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  last_modified_by: z.string().nullable(),
})

export type UsersOnboardingType = z.infer<typeof users_onboarding>
export type InsertableUsersOnboardingType = z.infer<typeof insertable_users_onboarding>
export type UpdateableUsersOnboardingType = z.infer<typeof updateable_users_onboarding>
export type SelectableUsersOnboardingType = z.infer<typeof selectable_users_onboarding>

import { defineStore } from "pinia";

import { z } from "zod";
import { useLocalStorage } from "@vueuse/core";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/relationships";

export const getRelationshipsSchema =
  schema["/dashboard/relationships"].get.response[200].shape.data;

const getWorkspacesSchema =
  schema["/dashboard/relationships/workspaces"].get.response[200].shape.data;

export type Relationship = z.infer<typeof getRelationshipsSchema.element>;

export const postRelationshipsSchema =
  schema["/dashboard/relationships"].post.body;
export const deleteRelationshipsSchema =
  schema["/dashboard/relationships"].delete.body;

export type Role = z.infer<typeof getRelationshipsSchema.element.shape.type>;
export type Scope =
  | "organizations"
  | "teams"
  | "contractors"
  | "employees"
  | "unknown";

export type PostRelationship = z.infer<
  typeof postRelationshipsSchema.shape.relationships.element
>;

export const useRelationshipsStore = defineStore("relationships", {
  state: (): {
    relationships: Ref<z.infer<typeof getRelationshipsSchema>>;
    relationshipsIncreased: boolean;
    workspaces: z.infer<typeof getWorkspacesSchema>;
  } => ({
    relationships: useLocalStorage("pinia/relationships/relationships", []),
    relationshipsIncreased: false,
    workspaces: [],
  }),
  getters: {
    workspacesNavigation(state) {
      const companies = [
        ...new Set(state.workspaces.map((e) => e.company_nanoid)),
      ];

      const workspaces = companies.map((company) => {
        const companyTeams = this.workspaces.filter(
          (e) => e.company_nanoid === company && e.team !== "Organization"
        );
        return {
          ...state.workspaces.find(
            (e) => e.company_nanoid === company && e.team === "Organization"
          ),
          teams: companyTeams,
        };
      });

      return workspaces;
    },
    currentWorkspace(state) {
      const workspaceId = this.$route.params.workspaceId;
      if (!workspaceId) return null;
      return state.workspaces.find((e) => e.nanoid === workspaceId) || null;
    },
    currentOrganization(state) {
      const workspaceId = this.$route.params.workspaceId;
      if (!workspaceId) return null;
      return state.workspaces.find((e) => e.nanoid === workspaceId) || null;
    },
    currentScope() {
      if (!this.currentWorkspace?.type) return "unknown";
      return this.roleScopes[this.currentWorkspace.type];
    },
    adminInviteSelectRoles(): { label: string; value: Role }[] {
      const keys = Object.keys(this.roleMap) as Role[];
      return keys
        .filter(
          (e) =>
            (e.includes("team_") || e.includes("org_")) && e !== "team_employee"
        )
        .map((e) => {
          return {
            label: this.roleMap[e],
            value: e,
          };
        });
    },
    teamInviteSelectRoles(): { label: string; value: Role }[] {
      const keys = Object.keys(this.roleMap) as Role[];
      return keys
        .filter(
          (e) =>
            e.includes("team_admin") ||
            e.includes("contractor") ||
            e.includes("team_employee")
        )
        .map((e) => {
          return {
            label: this.roleMap[e],
            value: e,
          };
        });
    },
    roleMap(): Record<Role, string> {
      return {
        payee: "Contractor",
        org_admin: "Organization Admin",
        org_finance_admin: "Organization Finance Admin",
        org_viewer: "Organization Viewer",
        team_admin: "Team Admin",
        team_finance_admin: "Team Finance Admin",
        team_viewer: "Team Viewer",
        contractor: "Contractor",
        team_employee: "Employee",
        company: "Owner",
        payer: "unknown",
        team: "Admin",
        user: "Account",
        viewer: "unknown",
      };
    },
    roleScopes(): Record<Role, Scope> {
      return {
        company: "organizations",
        team: "teams",
        org_admin: "organizations",
        org_finance_admin: "organizations",
        org_viewer: "organizations",
        team_admin: "teams",
        team_finance_admin: "teams",
        team_viewer: "teams",
        contractor: "contractors",
        team_employee: "employees",
        payee: "contractors",
        payer: "unknown",
        user: "unknown",
        viewer: "unknown",
      };
    },
    teamsSelectList(): { label: string; value: string }[] {
      if (!this.currentWorkspace) return [];
      const teams = this.currentWorkspace.teams;
      if (!teams) return [];
      return teams.map((team: Relationship) => {
        return {
          label: team.name as string,
          value: team.nanoid as string,
        };
      });
    },
  },
  actions: {
    async getWorkspaces() {
      const response = await this.$client[
        "/dashboard/relationships/workspaces"
      ].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      this.workspaces = getWorkspacesSchema.parse(responseJson.data);
    },
    async getRelationships(payload?: { checkLeftMenuPing?: boolean }) {
      const response = await this.$client["/dashboard/relationships"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      if (
        payload?.checkLeftMenuPing &&
        responseJson.data.length > this.relationships.length
      ) {
        this.relationshipsIncreased = true;
      }
      this.relationships = getRelationshipsSchema.parse(responseJson.data);
    },
    async createRelationships(
      relationships: PostRelationship[],
      userNanoid: string
    ) {
      const response = await this.$client["/dashboard/relationships"].post({
        json: {
          user_nanoid: userNanoid,
          relationships,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    async deleteRelationships(nanoids: string[]) {
      const response = await this.$client["/dashboard/relationships"].delete({
        json: {
          nanoids,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    isOrganization(role?: Role) {
      if (!role) return "";
      return ["org_admin", "org_finance_admin", "org_viewer"].includes(role);
    },
    scopeName(scope: Scope): string {
      const scopeNames: Record<Scope, string> = {
        organizations: "Organization",
        teams: "Team",
        employees: "Employee",
        contractors: "Contractor",
        unknown: "Unknown",
      };
      return scopeNames[scope];
    },
  },
});
